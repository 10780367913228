<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="">
        <v-row align="center" justify="center">
          <v-col cols="1">
            <v-checkbox
              @click.stop="toggleLayer"
              v-model="showLayer"
              class="menu-checkbox"
            ></v-checkbox>
          </v-col>
          <v-col class="ml-1 subtitle-1" style="padding-bottom: 16px"
            >{{ $t("rasterLayer") }}
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pl-8">
      <div v-if="!layerSelection.length">
        {{ $t("noRasterLayerAvailable") }}
      </div>
      <div v-else>
        <v-select
          :label="$t('layerSelection')"
          :items="layerSelection"
          v-model="selectedLayer"
          return-object
          item-text="title"
          class="py-0"
        ></v-select>
        <v-radio-group
          dense
          class="ml-0 mt-0 mb-3"
          v-model="selectedTempResolution"
          row
          hide-details
        >
          <v-radio
            v-for="n in aggregationModes"
            :key="n.mode"
            :label="n.text"
            :value="n.mode"
          ></v-radio>
        </v-radio-group>
        <eo-datetime-selection
          v-if="selectedTempResolution === 'all'"
          :init-selected-datetime="selectedDatetime"
          :layer-sensor-selection="sensorSelection"
          :datetime-selection="filteredDatetimeSelection"
          :aggregation-mode="selectedTempResolution"
          :layer="selectedLayer"
          :step-loading="stepLoading"
          @selectedDatetime="changeDatetime"
          @reloadDateTimes="reloadTimeSteps"
        />
        <eo-date-time-selection-manual-aggregation
          v-else-if="selectedTempResolution === 'custom'"
          :aggregation-mode="selectedTempResolution"
          :original-datetime-selection="datetimeSelection"
          @selectedDatetime="changeDatetime"
        >
        </eo-date-time-selection-manual-aggregation>
        <temp-res-date-time-selection
          v-else
          :aggregation-mode="selectedTempResolution"
          :datetime-selection="tempResDatetimeSelection"
          :init-selected-datetime="selectedDatetime"
          @selectedDatetime="changeDatetime"
        />
        <!--        <v-checkbox-->
        <!--          :disabled="selectedTempResolution !== 'all'"-->
        <!--          class="my-0 pa-0"-->
        <!--          dense-->
        <!--          hide-details-->
        <!--          :label="$t('addRgbBackground')"-->
        <!--          v-if="layerHasBackground"-->
        <!--          v-model="activeBackgroundLayer"-->
        <!--          @change="toggleBackground"-->
        <!--        ></v-checkbox>-->
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState, mapActions } from "vuex";
import filterMixin from "@/core/mixins/filter.mixin";
import EoDatetimeSelection from "@/core/components/utils/EoDatetimeSelection";
import TempResDateTimeSelection from "@/core/components/utils/TempResDateTimeSelection.vue";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import EoDateTimeSelectionManualAggregation from "@/core/components/utils/EoDateTimeSelectionManualAggregation.vue";

export default {
  name: "RasterLayerEntry",
  mixins: [filterMixin, rasterLayerMixin],
  components: {
    EoDateTimeSelectionManualAggregation,
    EoDatetimeSelection,
    TempResDateTimeSelection
  },
  data: () => ({
    showLayer: true,
    layerSelection: [],
    selectedLayer: {},
    datetimeSelection: [],
    tempResDatetimeSelection: [],
    selectedDatetime: {},
    selectedSensors: [],
    selectedTimestep: "",
    selectedTempResolution: "all",
    activeBackgroundLayer: false,
    selectedManualRange: 0,
    stepLoading: false,
    initialLoad: true
  }),
  computed: {
    ...mapState("management", ["accessToken", "tempResolution"]),
    ...mapState("raster", [
      "layerTimesteps",
      "selectedStep",
      "selectedPlotStep"
    ]),
    ...mapState("queryParams", ["layer", "timestep"]),
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    },
    seeQUT() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("lfurlp");
    },
    aggregationModes() {
      return [
        { mode: "all", text: this.$t("allSteps") },
        { mode: "daily", text: this.$t("daily") },
        {
          mode: "monthly",
          text: this.$t("monthly")
        },
        {
          mode: "custom",
          text: this.$t("custom")
        }
      ];
    },
    sensorSelection() {
      const selection = [];
      this.createLayerSelection(this.datetimeSelection, "sensor").forEach(
        object => {
          selection.push(object.value);
        }
      );
      return selection;
    },
    filteredDatetimeSelection() {
      let datetimeSelection = this.datetimeSelection;
      if (this.selectedTempResolution !== "all") {
        datetimeSelection = datetimeSelection.filter(
          step => step.temporalResolution === this.selectedTempResolution
        );
      }
      return datetimeSelection;
    },
    layerHasBackground() {
      return !!this.selectedLayer?.backgroundId;
    },
    layerIsActive() {
      return [
        this.selectedLayer?.layerId,
        this.selectedDatetime?.datetime,
        this.showLayer
      ].every(Boolean);
    },
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    ...mapActions("raster", [
      "setRasterLayer",
      "setLayerTimesteps",
      "setTempResLayerTimesteps",
      "setShowMetaDataWindow",
      "setSelectedStep",
      "setFilteredDatetimeSelection"
    ]),
    ...mapActions("management", [
      "updateTempResolution",
      "getOrFetchAccessToken"
    ]),
    ...mapActions("queryParams", ["setLayer", "setTimestep", "removeTimestep"]),
    async fetchLayers() {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${this.activeRegionId}&token=${this.accessToken}`
      );
      if (response.data.length > 0) {
        let data = response.data.sort((a, b) => a.title.localeCompare(b.title));
        if (client == "hypos") {
          data = data.reverse();
        }
        this.setRasterLayer(data);
        let filteredLayer = data;
        if (!this.isAdmin && !this.seeQUT) {
          filteredLayer = data.filter(layer => layer.product !== "qut");
        }
        this.layerSelection = filteredLayer;
        const filteredSelection = this.layerSelection.filter(
          layer => layer.product == "chl"
        );
        if (filteredSelection.length) {
          this.selectedLayer = filteredSelection[0];
        } else {
          this.selectedLayer = data[0];
        }
        if (this.$route.query.layer) {
          const layerFromURL = this.layerSelection.find(
            layer => layer.product === this.$route.query.layer
          );
          if (layerFromURL) {
            this.selectedLayer = layerFromURL;
          } else {
            if (filteredSelection.length) {
              this.selectedLayer = filteredSelection[0];
            } else {
              this.selectedLayer = data[0];
            }
          }
        } else {
          if (filteredSelection.length) {
            this.selectedLayer = filteredSelection[0];
          } else {
            this.selectedLayer = data[0];
          }
        }
      }
    },
    async fetchTimesteps() {
      this.stepLoading = true;
      this.datetimeSelection = await this.fetchAllTimesteps(
        this.selectedLayer.layerId,
        false
      );
      this.setLayerTimesteps(this.datetimeSelection);
      this.setFilteredDatetimeSelection(this.datetimeSelection);
      this.stepLoading = false;

      if (this.selectedTempResolution === "all") {
        const sameDatetime = this.datetimeSelection.find(
          item => item.datetime === this.selectedDatetime?.datetime
        );
        if (sameDatetime) {
          this.selectedDatetime = sameDatetime;
        } else {
          this.selectedDatetime = this.datetimeSelection[0];
        }
      }
      if (this.initialLoad && this.$route.query.timestep) {
        const timestepFromURL = this.datetimeSelection.find(
          timestep => timestep.datetime === this.$route.query.timestep
        );
        if (timestepFromURL) {
          this.selectedDatetime = timestepFromURL;
        } else {
          this.selectedDatetime = this.datetimeSelection[0];
        }
        this.initialLoad = false;
      }
    },

    createTempResDatetimeSelection(mode) {
      let datetimeSelectionSet = new Set();
      if (mode === "daily") {
        this.datetimeSelection.forEach(step => {
          datetimeSelectionSet.add(step.datetime.split("T")[0]);
        });
      } else if (mode === "monthly") {
        this.datetimeSelection.forEach(step => {
          const str = step.datetime.split("-");
          datetimeSelectionSet.add(str[0] + "-" + str[1]);
        });
      }
      datetimeSelectionSet = Array.from(datetimeSelectionSet);
      return datetimeSelectionSet;
    },

    changeDatetime(selectedDatetime) {
      if (this.selectedTempResolution === "all") {
        this.selectedDatetime = selectedDatetime;
        this.addLayer();
        this.toggleActiveLayerVuex();
      } else if (this.selectedTempResolution === "custom") {
        if (typeof selectedDatetime == "string") {
          this.selectedDatetime = {};
          if (selectedDatetime.includes(" - ")) {
            this.selectedDatetime.datetime = selectedDatetime.split(" - ")[1];
          } else {
            this.selectedDatetime.datetime = selectedDatetime;
          }
          const range = {
            startDate: selectedDatetime.split(" - ")[0],
            endDate: selectedDatetime.split(" - ")[1]
          };
          this.aggregateLayerbyManual(range);
          this.toggleActiveLayerVuex();
        }
        this.removeTimestep();
      } else {
        this.selectedDatetime = {};
        this.selectedDatetime.datetime = selectedDatetime;
        this.aggregateLayer(selectedDatetime);
        this.toggleActiveLayerVuex();
      }
      if (
        this.selectedDatetime?.datetime &&
        this.selectedStep.datetime !== this.selectedDatetime.datetime
      ) {
        this.setSelectedStep(this.selectedDatetime);
      }
    },
    resetComponent() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.fetchLayers();
    },
    async aggregateLayer(selectedStep) {
      this.$store.visualizeMap.U.removeSource(["rasterSource"]);
      this.aggregateRasterLayer(
        "raster",
        this.selectedLayer.layerId,
        this.startDate,
        selectedStep,
        "daily",
        "z-index-2",
        this.accessToken
      );
    },
    async aggregateLayerbyManual(range) {
      this.$store.visualizeMap.U.removeSource(["rasterSource"]);
      this.aggregateRasterLayerByRange(
        "raster",
        this.selectedLayer.layerId,
        range.startDate,
        range.endDate,
        "daily",
        "z-index-2",
        this.accessToken
      );
    },
    addLayer() {
      this.hideTerrain(this.$store.visualizeMap);
      this.$store.visualizeMap.U.removeSource([
        "rasterSource",
        "raster-terrainSource"
      ]);
      if (this.layerIsActive) {
        this.addRasterLayer(
          "raster",
          this.selectedLayer.layerId,
          this.selectedDatetime,
          "z-index-2",
          this.accessToken
        );
        this.addRasterLayer(
          "raster-terrain",
          this.selectedLayer.layerId,
          this.selectedDatetime,
          "z-index-1",
          this.accessToken,
          "raster-dem"
        );
        // if (this.activeBackgroundLayer) {
        //   this.addRasterLayer(
        //     "background",
        //     this.selectedLayer.backgroundId,
        //     this.selectedDatetime,
        //     "z-index-1",
        //     this.accessToken
        //   );
        // }
      }
    },
    toggleLayer() {
      if (this.showLayer) {
        this.$store.visualizeMap.U.showSource([
          "rasterSource",
          "raster-terrainSource"
          // "backgroundSource"
        ]);
      } else {
        this.$store.visualizeMap.U.hideSource([
          "rasterSource",
          "raster-terrainSource"
          // "backgroundSource"
        ]);
      }
      this.toggleActiveLayerVuex();
    },

    // toggleBackground() {
    //   if (this.activeBackgroundLayer) {
    //     this.addRasterLayer(
    //       "background",
    //       this.selectedLayer.backgroundId,
    //       this.selectedDatetime,
    //       "z-index-1",
    //       this.accessToken
    //     );
    //     this.$store.visualizeMap.moveLayer("backgroundLayer", "rasterLayer");
    //   } else {
    //     this.removeRasterLayer("backgroundSource");
    //   }
    // },
    toggleActiveLayerVuex() {
      if (this.layerIsActive) {
        const activeLayerInfo = {
          ...this.selectedDatetime,
          ...this.selectedLayer
        };
        this.$store.dispatch("raster/setActiveLayer", activeLayerInfo);
      } else {
        this.$store.dispatch("raster/setActiveLayer", null);
      }
    },
    reloadTimeSteps(index) {
      this.fetchTimesteps();
      if (index > 0) {
        this.selectedDatetime = this.datetimeSelection[index - 1];
      } else {
        this.selectedDatetime = this.datetimeSelection[index];
      }
    }
  },
  watch: {
    selectedStep() {
      this.selectedDatetime = this.selectedStep;
    },
    selectedPlotStep() {
      this.changeDatetime(this.selectedPlotStep);
    },
    selectedTempResolution() {
      this.tempResDatetimeSelection = this.createTempResDatetimeSelection(
        this.selectedTempResolution
      );
      this.updateTempResolution(this.selectedTempResolution);
      this.setTempResLayerTimesteps(this.tempResDatetimeSelection);
      this.setFilteredDatetimeSelection(this.tempResDatetimeSelection);
      // select Datetime depending on old selected datetime
      if (this.selectedTempResolution === "all") {
        let results = this.datetimeSelection.filter(datetimeObject => {
          if (this.selectedDatetime.datetime.length === 7) {
            //monthly
            const str = datetimeObject.datetime.split("-");
            const date_monthly = str[0] + "-" + str[1];
            return date_monthly === this.selectedDatetime.datetime;
          } else {
            //daily
            const date_daily = datetimeObject.datetime.split("T")[0];
            return date_daily === this.selectedDatetime.datetime;
          }
        });
        this.selectedDatetime = results[results.length - 1];
      }
    },
    selectedLayer: function() {
      this.fetchTimesteps();
      this.tempResDatetimeSelection = this.createTempResDatetimeSelection(
        this.selectedTempResolution
      );
      this.setFilteredDatetimeSelection(this.tempResDatetimeSelection);
      this.set;
      if (this.selectedTempResolution !== "all") {
        this.changeDatetime(this.selectedDatetime.datetime);
      }
    },
    activeRegion() {
      this.resetComponent();
    },
    selectedDatetime() {
      if (this.selectedDatetime) {
        this.setLayer(this.selectedLayer.product);
        if (this.selectedTempResolution !== "custom") {
          this.setTimestep(this.selectedDatetime.datetime);
        }
      }
    }
  },
  created() {
    this.getOrFetchAccessToken().then(() => {
      this.fetchLayers();
    });
  }
};
</script>

<style scoped></style>
