import { mapState } from "vuex";

let filterMixin = {
  computed: {
    ...mapState("raster", ["rasterLayer"]),
    ...mapState("vector", ["vectorLayer"])
  },
  methods: {
    createLayerSelection(json, property) {
      const entry_set = new Set(json.map(layer => layer[property]));
      entry_set.delete(undefined);
      let selection = [...entry_set].map(entry => ({
        value: entry,
        text: this.$t(entry)
      }));
      selection.push({ value: "all", text: this.$t("all") });
      return selection;
    },
    rasterLayerById(layerId) {
      return this.rasterLayer.find(layer => layer.id === layerId);
    },
    vectorLayerById(layerId) {
      return this.vectorLayer.find(layer => layer.id === layerId);
    }
  }
};

export default filterMixin;
