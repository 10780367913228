<template>
  <v-container>
    <v-row>
      <v-col class="pr-2 pl-0">
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="startDate"
              :label="$t('startDate')"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            />
          </template>
          <v-date-picker
            :value="startDate"
            @input="setStartDate"
            :min="minDate"
            :max="maxDate"
            ref="startPicker"
          />
        </v-menu>
      </v-col>
      <v-col class="pl-2 pr-0">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="endDate"
              :label="$t('endDate')"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            />
          </template>
          <v-date-picker
            :value="endDate"
            @input="setEndDate"
            :min="startDate"
            :max="maxDate"
            ref="endPicker"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EoStartEndDateSelection",
  props: {
    minDate: {
      type: String
    },
    startDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    endDate: {
      type: String
    }
  },
  data: function() {
    return {
      startDateMenu: false,
      endDateMenu: false
    };
  },
  methods: {
    setStartDate(date) {
      this.startDateMenu = false;
      this.$emit("changeStartDate", date);
    },
    setEndDate(date) {
      this.endDateMenu = false;
      this.$emit("changeEndDate", date);
    }
  },
  watch: {
    startDateMenu(val) {
      val && setTimeout(() => (this.$refs.startPicker.activePicker = "YEAR"));
    },
    endDateMenu(val) {
      val && setTimeout(() => (this.$refs.endPicker.activePicker = "YEAR"));
    }
  }
};
</script>

<style scoped></style>
