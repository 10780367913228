<template>
  <core-menu>
    <region-menu-header name="toolBarFeatures.mapView" icon="mdi-map-outline" />
    <v-divider />
    <v-divider />
    <v-expansion-panels
      accordion
      multiple
      v-model="activeLayerTypes"
      v-if="mapLoaded"
    >
      <raster-layer-entry />
      <vector-layer-entry />
      <!--      <virtual-station-layer-entry />-->
      <geojson-layer
        v-for="layer in geojsonLayers"
        :geojson-data="layer"
        :key="layer.name"
      ></geojson-layer>
      <!--    </v-expansion-panels>-->
      <!--    <template v-slot:append>-->
      <!--      <v-divider />-->
      <!--      <v-expansion-panels v-model="plotPanel" v-if="entriesDataCacheFilled">-->
      <!--        <v-expansion-panel expand v-model="plotPanel">-->
      <!--          <v-expansion-panel-header expand-icon="mdi-menu-up"-->
      <!--          >{{ $t("plotPreview") }}-->
      <!--          </v-expansion-panel-header>-->
      <!--          <v-expansion-panel-content id="menu-plot-content">-->
      <!--            <data-plot v-if="entriesDataCacheFilled" />-->
      <!--          </v-expansion-panel-content>-->
      <!--        </v-expansion-panel>-->
    </v-expansion-panels>
    <!--    </template>-->
  </core-menu>
</template>

<script>
import CoreMenu from "@/core/components/menu/CoreMenu";
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader";
import { mapGetters, mapState } from "vuex";
import RasterLayerEntry from "@/core/components/menu/eotool/visualize/RasterLayerEntry";
import VectorLayerEntry from "@/core/components/menu/eotool/visualize/VectorLayerEntry";
import Url from "@/core/services/url.services";
import axios from "axios";
import GeojsonLayer from "@/core/components/menu/GeojsonLayer.vue";

export default {
  name: "MapViewMenu",
  components: {
    CoreMenu,
    RegionMenuHeader,
    RasterLayerEntry,
    VectorLayerEntry,
    GeojsonLayer
  },
  data: () => ({
    activeLayerTypes: [0],
    plotPanel: 0,
    geojsonLayers: []
  }),
  computed: {
    ...mapState("app", ["visualizeMapLoaded"]),
    ...mapGetters("plot", ["entriesDataCacheFilled"]),
    ...mapGetters("management", ["activeRegion"]),
    mapLoaded() {
      return this.visualizeMapLoaded;
    }
  },
  methods: {
    async fetchGeojsonLayer() {
      const regionId = this.$route.params.regionId;
      const url = Url.geojsonLayer(regionId);
      const response = await axios.get(url);
      this.geojsonLayers = response.data;
    }
  },
  created() {
    if (this.$route.params.regionId) {
      this.fetchGeojsonLayer();
    }
  },
  watch: {
    "$route.params.regionId"() {
      this.fetchGeojsonLayer();
    }
  }
};
</script>

<style scoped>
#menu-plot-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.menu-checkbox {
  height: 30px;
  padding: 0;
  margin: auto 0;
}
</style>
