<template>
  <v-dialog width="600" v-model="this.associatePopUp" persistent>
    <v-card>
      <v-card-title>{{ $t("inSitu.associateDialog.title1") }}</v-card-title>

      <v-card-title
        style="word-break: keep-all"
        v-if="this.virtualStations.length"
        >{{ $t("inSitu.associateDialog.title2") }}</v-card-title
      >
      <v-card-title v-else>
        <p style="word-break: keep-all">
          {{ $t("inSitu.associateDialog.title2_v2_part_1") }}
        </p>
        <p style="word-break: keep-all">
          {{ $t("inSitu.associateDialog.title2_v2_part_2") }}
        </p>
      </v-card-title>

      <v-card-actions>
        <v-btn
          :color="'#6D7C90'"
          class="white--text"
          @click="navigate('InSituData')"
          >{{ $t("inSitu.associateDialog.buttonNo") }}</v-btn
        >
        <v-btn
          :disabled="!this.virtualStations.length"
          :color="'#414bb2'"
          class="white--text"
          @click="associate"
          >{{ $t("inSitu.associateDialog.buttonYes") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import axios from "axios";

export default {
  name: "AssociateDialogPopUp",
  data() {
    return {};
  },
  computed: {
    ...mapState("inSitu", ["associatePopUp"]),
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("vector", ["vectorLayer"]),
    ...mapState("draw", ["virtualStations"])
  },
  methods: {
    ...mapActions("inSitu", ["updateAssociatePopUpStatus"]),

    navigate(routeName) {
      this.$router.push({ name: routeName }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
      this.updateAssociatePopUpStatus(false);
    },
    async associate() {
      const client = Vue.prototype.$appConfig.keycloakClient;
      await axios.get(
        `/vector/${client}/region/${this.activeRegion.id}/layers/${
          this.vectorLayer[this.vectorLayer.length - 1].id
        }/match-stations`
      );
      this.navigate("InSituData");
      this.updateAssociatePopUpStatus(false);
    }
  }
};
</script>

<style scoped></style>
