<template>
  <v-container class="px-0 py-2">
    <v-row no-gutters>
      <v-col>
        <v-select
          :label="$t('datetime')"
          :items="datetimeItems"
          item-text="text"
          item-value="value"
          :value="selectedDatetime"
          class="py-0"
          @change="changeDatetime"
        >
          <v-icon
            slot="prepend"
            :disabled="disableNextButton"
            @click="previousDate"
          >
            mdi-menu-left
          </v-icon>
          <v-icon
            slot="append-outer"
            :disabled="disablePreviousButton"
            @click="nextDate"
          >
            mdi-menu-right
          </v-icon>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DatetimeSelection",
  props: ["datetimeItems", "selectedDatetime"],
  computed: {
    disableNextButton() {
      if (this.datetimeItems) {
        return (
          this.datetimeItems.indexOf(this.selectedDatetime) ===
          this.datetimeItems.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.datetimeItems) {
        return this.datetimeItems.indexOf(this.selectedDatetime) === 0;
      }
      return false;
    }
  },
  methods: {
    nextDate() {
      const selection = this.datetimeItems;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex > 0) {
        this.changeDatetime(selection[selectedPeriodIndex - 1]);
      } else if (selectedPeriodIndex === 0) {
        this.changeDatetime(selection[selection.length - 1]);
      }
    },
    previousDate() {
      const selection = this.datetimeItems;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex < selection.length - 1) {
        this.changeDatetime(selection[selectedPeriodIndex + 1]);
      }
    },
    changeDatetime(datetime) {
      this.$emit("changeDatetime", datetime);
    }
  }
};
</script>

<style scoped></style>
