<template>
  <v-container
    v-if="datetimeSelection && selectedDatetime.length > 1"
    class="px-0 py-2"
  >
    <v-row no-gutters>
      <v-col cols="10">
        <v-select
          :label="$t('datetime')"
          :items="this.datetimeSelection"
          return-object
          v-model="selectedDatetime"
          class="py-0"
        >
          <v-icon
            slot="prepend"
            :disabled="disableNextButton"
            @click="previousDate"
          >
            mdi-menu-left
          </v-icon>
          <v-icon
            slot="append-outer"
            :disabled="disablePreviousButton"
            @click="nextDate"
          >
            mdi-menu-right
          </v-icon>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TempResDateTimeSelection",
  props: {
    datetimeSelection: Array,
    initSelectedDatetime: Object
  },
  data() {
    return {
      selectedDatetime: ""
    };
  },
  computed: {
    ...mapState("management", ["tempResolution"]),
    disableNextButton() {
      if (this.datetimeSelection) {
        return (
          this.datetimeSelection.indexOf(this.selectedDatetime) ===
          this.datetimeSelection.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.datetimeSelection) {
        return this.datetimeSelection.indexOf(this.selectedDatetime) === 0;
      }
      return false;
    }
  },
  methods: {
    nextDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex > 0) {
        this.selectedDatetime = selection[selectedPeriodIndex - 1];
      } else if (selectedPeriodIndex === 0) {
        this.selectedDatetime = selection[selection.length - 1];
      }
    },
    previousDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex < selection.length - 1) {
        this.selectedDatetime = selection[selectedPeriodIndex + 1];
      }
    }
  },
  watch: {
    selectedDatetime: {
      handler(value) {
        this.$emit("selectedDatetime", value);
      }
    },
    initSelectedDatetime() {
      if (this.selectedDatetime != this.initSelectedDatetime.datetime) {
        this.selectedDatetime = this.initSelectedDatetime.datetime;
      }
    },

    tempResolution() {
      if (this.tempResolution == "daily") {
        let res = this.datetimeSelection.filter(datetime => {
          const str = datetime.split("-");
          const date_monthly = str[0] + "-" + str[1];
          return date_monthly == this.selectedDatetime;
        });
        this.selectedDatetime = res[res.length - 1];
      } else {
        const str = this.selectedDatetime.split("-");
        this.selectedDatetime = str[0] + "-" + str[1];
      }
    }
  },
  beforeMount() {
    if (this.tempResolution == "daily") {
      this.selectedDatetime = this.initSelectedDatetime.datetime.split("T")[0];
    } else if (this.tempResolution == "monthly") {
      const str = this.initSelectedDatetime.datetime.split("-");
      this.selectedDatetime = str[0] + "-" + str[1];
    }
  }
};
</script>

<style></style>
