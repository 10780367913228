<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <template v-if="!!vectorLayer.length" v-slot:activator="{ on, attrs }">
        <v-btn
          id="upload_btn"
          color="#039BE5"
          elevation="1"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: white">
            {{ $t(uploadDataTitle) }}
          </span>
        </v-btn>
      </template>
      <template v-else v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          color="#039BE5"
          elevation="1"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white" :title="$t(uploadDataTitle)"
            >mdi-file-upload-outline</v-icon
          >
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ $t(uploadDataTitle) }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" style="padding-left: 5px"
                >mdi-information-outline
              </v-icon>
            </template>
            <span v-html="$t('inSituUploadInfo')"></span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-file-input
              accept=".txt, .csv"
              :label="$t('fileInput')"
              @change="uploadFile"
              v-model="file"
              :loading="uploadFileProgress"
              :rules="fileRules"
              :error="fileError"
              :error-messages="fileErrorMessages"
            >
            </v-file-input>
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
            <v-row no-gutters class="pb-3">
              <v-col>
                <v-select
                  v-model="seperator"
                  :items="seperatorItems"
                  item-value="val"
                  item-text="text"
                  :label="$t('seperator')"
                  required
                  hide-details
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="decimal"
                  :items="decimalItems"
                  item-value="val"
                  item-text="text"
                  :label="$t('decimalSign')"
                  required
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="background-color: #BCE0FA; margin-left: -24px; margin-right: -24px"
            >
              <v-col class="py-2 px-6 font-weight-bold" style="color: black">
                {{ $t("inSitu.uploadInSituData.important") }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="background-color: #BCE0FA; margin-left: -24px; margin-right: -24px"
            >
              <v-col class="py-2 px-6">
                <v-img src="/images/eoportal/inSituData/sampleFile.png">
                </v-img>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="background-color: #BCE0FA; margin-left: -24px; margin-right: -24px"
            >
              <v-col class="pt-1 px-6">
                {{ $t("inSitu.uploadInSituData.rule1") }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="background-color: #BCE0FA; margin-left: -24px; margin-right: -24px"
            >
              <v-col class="pt-0 px-6">
                {{ $t("inSitu.uploadInSituData.rule2") }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              style="background-color: #BCE0FA; margin-left: -24px; margin-right: -24px"
            >
              <v-col class="pt-0 px-6">
                {{ $t("inSitu.uploadInSituData.rule3") }}
              </v-col>
            </v-row>

            <v-expand-transition>
              <div v-show="showFileConfig" class="file-settings">
                <v-row no-gutters class="pt-2">
                  <v-col class="font-weight-bold">
                    {{ $t("chooseColumn") }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="stationName"
                      :items="columns"
                      :label="$t('stationName')"
                      required
                      @change="createDataColumns"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="datetime"
                      :items="columns"
                      :label="$t('datetime')"
                      required
                      @change="createDataColumns"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="lat"
                      :items="columns"
                      :label="$t('lat')"
                      required
                      @change="createDataColumns"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="lon"
                      :items="columns"
                      :label="$t('lon')"
                      required
                      @change="createDataColumns"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col class="font-weight-bold">
                    {{ $t("inSitu.uploadInSituData.info1") }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    {{ $t("inSitu.uploadInSituData.info2") }}
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="dataHeaders"
                  fixed-header
                  :height="tableHeight"
                  :items="dataColumns"
                  hide-default-footer
                  class="elevation-0 custom-scrollbar"
                >
                  <template v-slot:item.name="{ item, index }">
                    <v-select
                      :value="selectedItem"
                      :items="['none', ...eOProducts]"
                      :label="$t('inSitu.uploadInSituData.select')"
                      @input="updateAssociatedEOProduct(index, item, $event)"
                    ></v-select>
                  </template>

                  <template v-slot:item.unit="{ item, index }">
                    <v-edit-dialog
                      :value="item.unit"
                      @save="saveNewUnit(index, item)"
                      v-if="isUnitEditable(index, selectedValues[index])"
                      :return-value.sync="item.unit"
                      large
                    >
                      <div>
                        {{ item.unit }}
                        <v-icon small>mdi-pencil</v-icon>
                      </div>

                      <template v-slot:input>
                        <div class="mt-4 title">
                          {{ $t("unit") }} {{ $t("fit") }}
                        </div>
                        <v-text-field
                          v-model="item.unit"
                          label="Edit"
                          single-line
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <span v-else>{{ item.unit }}</span>
                  </template>
                </v-data-table>
              </div>
            </v-expand-transition>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-progress-linear indeterminate v-show="uploadVectorLoading" />
        <v-card-actions>
          <v-row justify="start" no-gutters>
            <v-col cols="auto pa-1" v-if="!this.file">
              <v-btn class="px-2" id="sampleFile" text href="/docs/sample.csv">
                <v-icon color="#079EE3" :title="$t('sampleFileInfo')"
                  >mdi-download-circle-outline</v-icon
                >
                <span class="pa-1" style="color: #039BE5">{{
                  $t("inSitu.uploadInSituData.download")
                }}</span>
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto pa-1">
              <v-btn
                color="#e6e6e6"
                @click="cancelUpload"
                :disabled="uploadVectorLoading"
              >
                {{ $t("flagging.cancel") }}
              </v-btn>
            </v-col>
            <v-col cols="auto pa-1">
              <v-btn
                :color="'#039BE5'"
                class="white--text"
                @click="upload"
                :disabled="isButtonDisabled"
              >
                {{ $t("inSitu.uploadInSituData.upload") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <associate-dialog-pop-up></associate-dialog-pop-up>
  </div>
</template>

<script>
import axios from "axios";
import Url from "@/core/services/url.services";
import { mapActions, mapGetters, mapState } from "vuex";
import AssociateDialogPopUp from "@/core/components/inSituData/AssociateDialogPopUp";

export default {
  name: "UploadVectorData",
  components: { AssociateDialogPopUp },
  props: {
    uploadTitle: {
      type: String,
      default: "uploadData"
    }
  },
  data: function() {
    return {
      dialog: false,
      uploadDataTitle: this.uploadTitle,
      name: "",
      nameRules: [v => !!v || this.$t("nameRequired")],
      seperator: ",",
      seperatorItems: [
        { val: ",", text: this.$t("comma") },
        { val: ";", text: this.$t("semicolon") },
        { val: ".", text: this.$t("point") },
        { val: "\t", text: this.$t("tab") }
      ],
      decimal: ".",
      decimalItems: [
        { val: ",", text: this.$t("comma") },
        { val: ".", text: this.$t("point") }
      ],
      file: null,
      fileRules: [v => !!v || this.$t("fileRequired")],
      fileError: false,
      fileErrorMessages: [],
      uploadFileProgress: false,
      selectedFileId: null,
      columns: [],
      dataColumns: [],
      lat: "",
      lon: "",
      datetime: "",
      stationName: "",
      dataHeaders: [
        { text: this.$t("parameter"), value: "id" },
        {
          text: this.$t("inSitu.uploadInSituData.associatedEOProduct"),
          value: "name"
        },
        { text: this.$t("unit"), value: "unit" }
      ],
      uploadVectorLoading: false,
      selectedItem: "none",
      setUnit: "",
      selectedValues: [],
      originalNames: [],
      updatedUnit: ""
    };
  },
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("raster", ["rasterLayer"]),
    ...mapState("vector", ["vectorLayer"]),
    ...mapState("inSitu", ["uploadInSituDataDialogVisible"]),
    isButtonDisabled() {
      return !this.file || !this.name;
    },
    showFileConfig() {
      return !!this.selectedFileId;
    },
    eOProducts() {
      return this.rasterLayer
        .filter(item => item.product !== "rgb" && item.product !== "qut")
        .map(item => item.product.toUpperCase() + " " + "(" + item.title + ")");
    },
    tableHeight() {
      const noOfRows = this.dataColumns.length;
      if (noOfRows === 0) {
        return "90px";
      }
      const maxNoOfRows = 10;
      if (noOfRows > maxNoOfRows) {
        return "400px";
      } else {
        const height = noOfRows * 70 + 60;
        return height > 400 ? "400px" : height + "px";
      }
    }
  },
  methods: {
    ...mapActions("vector", ["fetchVectorLayer"]),
    ...mapActions("app", ["showSnackbar"]),
    ...mapActions("inSitu", ["updateAssociatePopUpStatus", "closeDialog"]),
    async uploadFile() {
      this.dataColumns = [];
      this.fileError = false;
      this.fileErrorMessages = [];
      if (this.file) {
        this.uploadFileProgress = true;
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("seperator", this.seperator);
        formData.append("decimal_sign", this.decimal);
        try {
          const response = await axios.post(Url.uploadVectorData(), formData, {
            "Content-Type": "multipart/form-data"
          });
          this.columns = response.data.columns;
          this.selectedFileId = response.data.id;
          this.setDefaultValues();
          this.createDataColumns();
        } catch (e) {
          this.fileError = true;
          const errorCode = e.response.data[0];
          this.fileErrorMessages = [this.$t(errorCode)];
          this.file = null;
        } finally {
          this.uploadFileProgress = false;
        }
      } else {
        this.deleteSelectedFile();
      }
    },
    setDefaultValues() {
      if (this.columns.length >= 4) {
        const nameIndex =
          this.columns.indexOf("name") != -1 ? this.columns.indexOf("name") : 0;
        this.stationName = this.columns[nameIndex];
        const dateIndex =
          this.columns.indexOf("date") != -1 ? this.columns.indexOf("date") : 1;
        this.datetime = this.columns[dateIndex];
        const latIndex =
          this.columns.indexOf("lat") != -1 ? this.columns.indexOf("lat") : 2;
        this.lat = this.columns[latIndex];
        const lonIndex =
          this.columns.indexOf("lon") != -1 ? this.columns.indexOf("lon") : 3;
        this.lon = this.columns[lonIndex];
      }
    },
    isUnitEditable(index, selectedValue) {
      return (
        selectedValue === "none" ||
        !this.rasterLayer.some(
          productItem => productItem.product === selectedValue
        )
      );
    },
    updateAssociatedEOProduct(index, templateItem, selectedValue) {
      this.selectedValues[index] = selectedValue.split(" ")[0].toLowerCase();

      let productUnit = "";
      let rasterLayerID = null;

      if (selectedValue !== "none") {
        const productItem = this.rasterLayer.find(
          item => item.product === selectedValue.split(" ")[0].toLowerCase()
        );

        if (productItem) {
          productUnit = productItem.unit;
          rasterLayerID = productItem.layerId;
        }
      } else {
        selectedValue = this.originalNames[index];
      }

      const existingDataColumn = this.dataColumns[index];
      existingDataColumn.name = selectedValue.split(" ")[0].toLowerCase();
      existingDataColumn.unit = productUnit;
      existingDataColumn.rastless_layer_id = rasterLayerID;

      this.$set(this.dataColumns, index, existingDataColumn);
    },
    saveNewUnit(index, item) {
      this.setUnit = item.unit;
    },

    createDataColumns() {
      let dataColumns = this.columns.filter(
        elem =>
          ![this.lat, this.lon, this.datetime, this.stationName].includes(elem)
      );
      this.originalNames = dataColumns;

      this.dataColumns = dataColumns.map(elem => ({
        id: elem,
        name: elem,
        unit: "",
        dtype: "float64",
        category: "",
        rastless_layer_id: null
      }));
    },
    deleteSelectedFile() {
      if (this.selectedFileId) {
        axios.delete(Url.uploadVectorData(this.selectedFileId));
        this.selectedFileId = null;
      }
    },
    cancelUpload() {
      this.dataColumns = [];
      this.deleteSelectedFile();
      this.file = null;
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.$store.dispatch("inSitu/closeDialog");
    },

    async upload() {
      this.dialog = false;
      await this.$store.dispatch("inSitu/closeDialog");

      this.updateAssociatePopUpStatus(true);
      let validForm = this.$refs.form.validate();
      if (validForm) {
        this.uploadVectorLoading = true;
        try {
          await axios.post(Url.vectorLayer(), {
            name: this.name,
            file: this.selectedFileId,
            lat_column: this.lat,
            lon_column: this.lon,
            station_name_column: this.stationName,
            datetime_column: this.datetime,
            region: this.activeRegion.id,
            parameter: this.dataColumns
          });
          this.successfulSubmit();
        } catch (e) {
          console.log(e);
        } finally {
          this.uploadVectorLoading = false;
        }
      }
    },
    async successfulSubmit() {
      this.file = null;
      this.$refs.form.resetValidation();
      this.dialog = false;
      await this.$store.dispatch("inSitu/closeDialog");
      await this.fetchVectorLayer(this.activeRegion.id);
      this.showSnackbar({
        show: true,
        message: this.$t("vectorLayerUpdated"),
        color: "success"
      });
      this.dataColumns = [];
    }
  },
  created() {
    this.dialog = this.uploadInSituDataDialogVisible;
  }
};
</script>

<style scoped>
.file-settings {
  margin-top: 5px;
}
.custom-scrollbar ::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}
#upload_btn,
#sampleFile {
  text-transform: unset !important;
}
</style>
