<template>
  <v-expansion-panel v-if="showVectorMenuEntry">
    <v-expansion-panel-header>
      <template v-slot:default="">
        <v-row align="center" justify="center">
          <v-col cols="1">
            <v-checkbox
              @click.stop=""
              @change="toggleLayer"
              v-model="showLayer"
              class="menu-checkbox"
            ></v-checkbox>
          </v-col>
          <v-col class="ml-1 subtitle-1" style="padding-bottom: 16px"
            >{{ $t("vectorLayer") }}
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pl-8">
      <v-select
        :label="$t('inSitu.inSituAndModelData.inSituLayer')"
        :items="vectorLayer"
        v-model="selectedLayer"
        return-object
        item-value="id"
        item-text="name"
        class="py-0"
        @change="resetCategorySelection"
      />
      <v-select
        v-if="showCategory"
        :label="$t('categorySelection')"
        :items="categoryItems"
        v-model="selectedCategory"
        item-value="id"
        item-text="name"
        class="py-0"
        @change="resetParameterSelection"
      />
      <v-select
        :label="$t('inSitu.inSituAndModelData.inSituParameter')"
        class="py-0"
        :items="parameterItems"
        v-model="selectedParameter"
        return-object
        item-value="id"
        item-text="name"
        :hide-details="showDateSelection"
      />
      <v-select
        :value="selectedInSituStation.name"
        :items="inSituStations"
        item-text="name"
        :label="$t('inSitu.inSituAndModelData.inSituStation')"
        return-object
        @change="updateInSituStation"
      />
      <datetime-selection
        v-if="showTimeStepSelection"
        class="py-0"
        :label="$t('datetime')"
        :datetime-items="timeStepItems"
        :selected-datetime="selectedTimestep"
        @changeDatetime="setDatetime"
      />
      <eo-start-end-date-selection
        v-if="showDateSelection"
        :min-date="minDate"
        :max-date="maxDate"
        :start-date="startDate"
        :end-date="endDate"
        @changeStartDate="setStartDate"
        @changeEndDate="setEndDate"
      />
      <v-row>
        <v-col cols="12">
          <v-checkbox
            :label="$t('showLabels')"
            dense
            v-model="showLabels"
            @click="toggleLabels"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="$keycloak.authenticated" class="text-center">
          <upload-vector-data />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
  <v-expansion-panel v-else>
    <v-row class="mx-5 ml-11 my-1">
      <v-col class="subtitle-1" cols="auto">{{ $t("vectorLayer") }} </v-col>
      <v-spacer />
      <v-col cols="auto" class="mr-0 pr-0" v-if="$keycloak.authenticated">
        <upload-vector-data />
      </v-col>
    </v-row>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import filterMixin from "@/core/mixins/filter.mixin";
import EoStartEndDateSelection from "@/core/components/utils/EoStartEndDateSelection";
import vectorLayerMixin from "@/core/mixins/vectorLayer.mixin";
import UploadVectorData from "@/core/components/menu/eotool/visualize/vector-layer-entry/UploadVectorData";
import DatetimeSelection from "@/core/components/menu/eotool/visualize/vector-layer-entry/DatetimeSelection";

export default {
  name: "VectorLayerEntry",
  mixins: [filterMixin, vectorLayerMixin],
  components: { EoStartEndDateSelection, UploadVectorData, DatetimeSelection },
  data: function() {
    return {
      showLayer: true,
      selectedLayerTemp: null,
      selectedCategory: this.$t("all"),
      selectedParameterTemp: null,
      selectedTimestepTemp: null,
      startDateTemp: null,
      endDateTemp: null,
      showLabels: false
    };
  },
  computed: {
    ...mapState("vector", ["vectorLayer", "activeVectorLayer"]),
    ...mapState("inSitu", ["inSituStations", "selectedInSituStation"]),
    ...mapGetters("management", ["activeRegion"]),
    showVectorMenuEntry() {
      return !!this.vectorLayer.length;
    },
    selectedLayer: {
      get() {
        return this.selectedLayerTemp || this.vectorLayer[0];
      },
      set(layer) {
        this.selectedLayerTemp = layer;
      }
    },
    selectedLayerParameter() {
      let selectedLayer = this.vectorLayer.find(
        layer => layer.id === this.selectedLayer.id
      );
      return selectedLayer.properties;
    },

    showCategory() {
      return this.categoryItems.length > 1;
    },
    categoryItems() {
      let categories = this.selectedLayerParameter
        .filter(property => property.category)
        .map(property => property.category);
      categories.unshift(this.$t("all"));
      return categories;
    },
    parameterItems() {
      let parameter = [...this.selectedLayerParameter];
      if (this.selectedCategory !== this.$t("all")) {
        parameter = parameter.filter(
          parameter => parameter.category === this.selectedCategory
        );
      }
      return parameter;
    },
    selectedParameter: {
      get() {
        return this.selectedParameterTemp || this.parameterItems[0];
      },
      set(parameter) {
        this.selectedParameterTemp = parameter;
      }
    },
    showDateSelection() {
      return (
        this.selectedLayer.shp_type === "timeseries" //this.selectedLayer.date_range
      );
    },
    minDate() {
      if (this.selectedLayer.date_range) {
        return this.selectedLayer.date_range.min;
      }
      return null;
    },
    maxDate() {
      if (this.selectedLayer.date_range) {
        return this.selectedLayer.date_range.max;
      }
      return null;
    },
    startDate: {
      get() {
        return this.startDateTemp || this.minDate;
      },
      set(date) {
        this.startDateTemp = date;
      }
    },
    endDate: {
      get() {
        return this.endDateTemp || this.maxDate;
      },
      set(date) {
        this.endDateTemp = date;
      }
    },
    showTimeStepSelection() {
      return (
        this.selectedLayer.shp_type === "timesteps" &&
        this.selectedLayer.date_range
      );
    },
    timeStepItems() {
      return this.selectedLayer.time_steps || [];
    },
    selectedTimestep: {
      get() {
        return this.selectedTimestepTemp || this.timeStepItems[0];
      },
      set(datetime) {
        this.selectedTimestepTemp = datetime;
      }
    },
    activeSelection() {
      if (this.selectedLayer) {
        return {
          layerId: this.selectedLayer.id,
          layerName: this.selectedLayer.name,
          parameterId: this.selectedParameter.id,
          parameterName: this.selectedParameter.name,
          unit: this.selectedParameter.unit,
          startDate: this.startDate,
          endDate: this.endDate,
          style: this.selectedLayer.style,
          shpType: this.selectedLayer.shp_type,
          timestep: this.selectedTimestep
        };
      }
      return null;
    }
  },
  methods: {
    ...mapActions("vector", ["setActiveVectorLayer", "fetchVectorLayer"]),
    ...mapActions("inSitu", ["fetchInSituData", "updateSelectedInSituStation"]),

    async resetCategorySelection() {
      this.startDateTemp = null;
      this.endDateTemp = null;
      this.selectedParameterTemp = null;
      this.selectedCategory = this.$t("all");
      if (this.$keycloak.authenticated) {
        await this.fetchVectorLayer(this.activeRegion.id).then(async () => {
          await this.fetchInSituData({
            vector_layer_id: this.activeVectorLayer.layerId
          });
        });
      }
    },
    resetParameterSelection() {
      this.selectedParameterTemp = null;
    },
    setStartDate(val) {
      this.startDate = val;
    },
    setEndDate(val) {
      this.endDate = val;
    },
    setDatetime(val) {
      this.startDate = val;
      this.endDate = val;
      this.selectedTimestep = val;
    },
    addLayer(activeSelection) {
      this.$store.visualizeMap.U.removeSource("vectorSource");
      this.addVector(activeSelection);
    },
    toggleLayer(val) {
      if (val) {
        this.$store.visualizeMap.U.showSource("vectorSource");
      } else {
        this.$store.visualizeMap.U.hideSource("vectorSource");
      }
    },
    updateInSituStation(val) {
      this.updateSelectedInSituStation(val);
      const coordinates = val.geometry.coordinates;

      this.$store.visualizeMap.flyTo({
        center: coordinates,
        essential: true,
        zoom: 17
      });
    }
  },
  async created() {
    if (this.$keycloak.authenticated) {
      await this.fetchVectorLayer(this.activeRegion.id).then(async () => {
        await this.fetchInSituData({
          vector_layer_id: this.activeVectorLayer.layerId
        });
      });
    }
  },
  beforeDestroy() {
    this.selectedLayer = null;
    this.$store.commit("vector/SET_VECTOR_LAYER", []);
  },
  watch: {
    activeSelection: {
      handler(activeLayer) {
        if (activeLayer) {
          let layerRequestData = Object.assign({}, activeLayer);
          let layerPopupData = Object.assign({}, activeLayer);

          if (this.selectedLayer.shp_type === "timesteps") {
            layerRequestData.startDate = this.selectedTimestep;
            layerRequestData.startDate = this.selectedTimestep;

            layerPopupData.startDate = this.minDate;
            layerPopupData.endDate = this.maxDate;
          }
          layerPopupData.style = activeLayer.style;
          this.addLayer(layerRequestData);
          this.setActiveVectorLayer(layerPopupData);
          this.toggleLabels();
        } else {
          this.setActiveVectorLayer({
            layerId: 0,
            layerName: "",
            parameterId: "",
            parameterName: "",
            startDate: "",
            endDate: "",
            unit: "",
            shpType: "",
            timestep: "",
            style: {}
          });
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
