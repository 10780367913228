<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="">
        <v-row align="center" justify="center">
          <v-col cols="1">
            <v-checkbox
              @click.stop="toggleLayer"
              v-model="showLayer"
              class="menu-checkbox"
            ></v-checkbox>
          </v-col>
          <v-col class="ml-1" style="padding-bottom: 16px">
            {{ geojsonData.name }}
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-select
        :label="$t('datetime')"
        :items="datetimeSelection"
        v-model="selectedDatetime"
        @change="setLayerData"
      >
        <v-icon slot="prepend" :disabled="isLastDate" @click="previousDate">
          mdi-menu-left
        </v-icon>
        <v-icon slot="append-outer" :disabled="isFirstDate" @click="nextDate">
          mdi-menu-right
        </v-icon>
      </v-select>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Url from "@/core/services/url.services";
import axios from "axios";

export default {
  name: "GeojsonLayer",
  props: {
    geojsonData: {
      type: Object,
      required: true,
      default() {
        return {
          name: "",
          style: {
            name: "",
            type: "",
            layout: {},
            paint: {}
          },
          timesteps: []
        };
      }
    }
  },
  data: function() {
    return {
      showLayer: false,
      selectedDatetime: this.geojsonData.timesteps[0].datetime,
      geojsonStore: {}
    };
  },
  computed: {
    datetimeSelection() {
      return this.geojsonData.timesteps.map(item => ({
        value: item.datetime,
        text: item.datetime.replace("T", "\u00A0\u00A0\u00A0\u00A0")
      }));
    },
    isLastDate() {
      const selectedIndex = this.searchIndex(
        this.selectedDatetime,
        this.datetimeSelection
      );
      return selectedIndex === this.datetimeSelection.length - 1;
    },
    isFirstDate() {
      const selectedIndex = this.searchIndex(
        this.selectedDatetime,
        this.datetimeSelection
      );
      return selectedIndex === 0;
    }
  },
  methods: {
    toggleLayer() {
      if (this.showLayer) {
        this.$store.visualizeMap.U.showSource([this.geojsonData.name]);
      } else {
        this.$store.visualizeMap.U.hideSource([this.geojsonData.name]);
      }
    },
    initGeojsonLayer() {
      this.$store.visualizeMap.addSource(this.geojsonData.name, {
        type: "geojson",
        data: {}
      });

      let styleProperties = {
        id: this.geojsonData.name,
        type: this.geojsonData.style.type,
        source: this.geojsonData.name,
        paint: this.geojsonData.style.paint
      };

      if (this.geojsonData.style.layout) {
        styleProperties.layout = this.geojsonData.style.layout;
      }

      this.$store.visualizeMap.addLayer(styleProperties);

      this.$store.visualizeMap.U.hideSource([this.geojsonData.name]);
    },
    async fetchGeojson() {
      const regionId = this.$route.params.regionId;
      const url = Url.geojsonData(regionId, this.selectedDatetime);
      const response = await axios.get(url);
      return response.data;
    },
    async setLayerData() {
      let data;
      if (this.selectedDatetime in this.geojsonStore) {
        data = this.geojsonStore[this.selectedDatetime];
      } else {
        data = await this.fetchGeojson();
        this.geojsonStore[this.selectedDatetime] = data;
      }
      this.$store.visualizeMap.getSource(this.geojsonData.name).setData(data);
    },
    searchIndex(selected, selection) {
      for (let i = 0; i < selection.length; i++) {
        if (selection[i].value === selected) {
          return i;
        }
      }
      return -1;
    },
    previousDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;

      const currentIndex = this.searchIndex(selected, selection);

      if (currentIndex < this.datetimeSelection.length - 1) {
        this.selectedDatetime = this.datetimeSelection[currentIndex + 1].value;
        this.setLayerData();
      }
    },
    nextDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;
      const currentIndex = this.searchIndex(selected, selection);

      if (currentIndex > 0) {
        this.selectedDatetime = this.datetimeSelection[currentIndex - 1].value;
        this.setLayerData();
      } else if (currentIndex === 0) {
        this.selectedDatetime = selection[selection.length - 1];
      }
    }
  },
  created() {
    this.initGeojsonLayer();
    this.setLayerData();
  }
};
</script>

<style scoped></style>
