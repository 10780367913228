<template>
  <v-container class="px-0 py-2 pt-0">
    <v-row class="pb-2 pl-8">
      <v-col cols="auto" class="pr-0">{{ $t("aggregationStep") }}:</v-col>
      <v-col class="py-0 my-0" cols="3">
        <v-text-field
          v-model="selectedAggregationStep"
          type="number"
          class=" py-1 my-0"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-icon color="primary" @click="aggregateLayerManually"
          >mdi-arrow-right</v-icon
        >
      </v-col>
      <v-spacer />
    </v-row>
    <v-row no-gutters v-if="datetimeSelection.length">
      <v-col cols="10">
        <v-select
          :label="$t('datetimeRange')"
          :items="this.datetimeSelection"
          return-object
          v-model="selectedDatetime"
          class="py-0"
        >
          <v-icon
            slot="prepend"
            :disabled="disableNextButton"
            @click="previousDate"
          >
            mdi-menu-left
          </v-icon>
          <v-icon
            slot="append-outer"
            :disabled="disablePreviousButton"
            @click="nextDate"
          >
            mdi-menu-right
          </v-icon>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import filterMixin from "@/core/mixins/filter.mixin";
import rasterLayerMixin from "@/core/mixins/rasterLayer.mixin";
import moment from "moment";

export default {
  name: "EoDateTimeSelectionManualAggregation",
  props: {
    originalDatetimeSelection: Array
  },
  mixins: [filterMixin, rasterLayerMixin],
  data: () => ({
    layerSelection: [],
    datetimeSelection: [],
    selectedDatetime: {}
  }),
  computed: {
    ...mapState("management", ["tempResolution", "aggregationStep"]),
    selectedAggregationStep: {
      get() {
        return this.aggregationStep;
      },
      set(value) {
        this.updateAggregationStep(value);
      }
    },
    disableNextButton() {
      if (this.datetimeSelection) {
        return (
          this.datetimeSelection.indexOf(this.selectedDatetime) ===
          this.datetimeSelection.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.datetimeSelection) {
        return this.datetimeSelection.indexOf(this.selectedDatetime) === 0;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("management", ["updateAggregationStep"]),
    ...mapActions("raster", ["setTempResLayerTimesteps"]),
    nextDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex > 0) {
        this.selectedDatetime = selection[selectedPeriodIndex - 1];
      } else if (selectedPeriodIndex === 0) {
        this.selectedDatetime = selection[selection.length - 1];
      }
    },
    previousDate() {
      const selection = this.datetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex < selection.length - 1) {
        this.selectedDatetime = selection[selectedPeriodIndex + 1];
      }
    },
    defineDateRanges() {
      const startDate = moment(
        this.originalDatetimeSelection[0].datetime.split("T")[0]
      );
      const endDate = moment(
        this.originalDatetimeSelection[
          this.originalDatetimeSelection.length - 1
        ].datetime.split("T")[0]
      );
      const manualStepArray = [];
      let movingtimeStep = startDate;
      while (movingtimeStep <= startDate && movingtimeStep >= endDate) {
        const tempBegin = movingtimeStep;
        movingtimeStep = moment(movingtimeStep).subtract(
          this.aggregationStep,
          "days"
        );
        const tempEnd = movingtimeStep;
        const dataExists = this.checkDateRanges(tempBegin, tempEnd);
        if (dataExists) {
          manualStepArray.push(
            `${tempEnd.format("YYYY-MM-DD")} - ${tempBegin.format(
              "YYYY-MM-DD"
            )}`
          );
        }
        movingtimeStep = moment(movingtimeStep).subtract(1, "days");
      }
      return manualStepArray;
    },
    checkDateRanges(startDate, endDate) {
      // check if data exists in each daterange
      const existingData = this.originalDatetimeSelection.filter(
        step =>
          moment(step.datetime) <= startDate && moment(step.datetime) >= endDate
      );
      if (existingData.length) {
        return true;
      } else {
        return false;
      }
    },
    aggregateLayerManually() {
      this.datetimeSelection = this.defineDateRanges();
      this.setTempResLayerTimesteps(this.datetimeSelection);
      this.selectedDatetime = this.datetimeSelection[0];
    }
  },
  watch: {
    selectedDatetime: {
      handler(value) {
        this.$emit("selectedDatetime", value);
      },
      immediate: true
    }
  },
  created() {
    if (this.selectedAggregationStep != 0) {
      this.aggregateLayerManually();
    }
  }
};
</script>

<style scoped></style>
